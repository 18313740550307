<template>
  <div style=" background:#4C7EF9;">
    <div style="display: flex; align-items: center;  position: relative; justify-content: center; width: 100%; margin-bottom: 20px;">
      <topbutton router="snowguan" style="position: absolute; left: 20px; top: 30px;"></topbutton>
      <div style="margin-top: 30px; font-size: 18px; font-weight: 500; color: var( --text-color);">星级级别</div>
      <div></div>
    </div>
    <div class="shequ">
    
   
    <!-- <p class="s_title">{{ $t('lang.d59') }}</p> -->
    <!-- <div class="vvs">
      <div class="vv " :id="`vv${index}`" v-for="(item, index) in tuanduilist"
        :style="`background: url(${imgurl}${item.logo}) no-repeat;`">
        <div class="rate" v-show="item.reward_rate != 0" :style="`background: ${vvlist[item.id]};`">+{{
          item.reward_rate * 100 }}%</div>
        <div class="biao" v-if="item.id == uptuandui.team_level" :style="`background:${vvlist[item.id]};`">{{
          $t('lang.d60') }}</div>
        <div class="biao" v-else :style="`background:${vvlist[item.id]};`">{{ uptuandui.team_level > item.id ? '已完成' :
          $t('lang.d72') }}</div>
        <div class="text" :style="`color: ${vvlist[item.id]};`" v-if="item.id < 2">

          <div style="font-size: 14px;">
            {{ $t('lang.d70') }}D{{ index + 1 }}
          </div>

          <div style=" font-size: 12px; margin-top: 5px;">
            {{ $t('lang.d71') }}({{ uptuandui.team_achievement }}/{{ tuanduilist[index + 1].team_achievement }})
          </div>
          <van-progress :show-pivot="false"
            :percentage="percentageis(uptuandui.team_achievement / tuanduilist[index + 1].team_achievement * 100)"
            track-color="var( --text-color)" :color="vvlist[index]" />
        </div>
        <div class="text" :style="`color: ${vvlist[item.id]};`" v-else-if="item.id >= 2 && item.id < 6">

          <div style="font-size: 14px;">
            {{ $t('lang.d70') }}D{{ item.id + 1 }}
          </div>

          <div style=" font-size: 12px; margin-top: 5px;">
            {{ $t('lang.d73') }}({{ shibum[item.id - 1] }}/{{ tuanduilist[index + 1].team_line_pre }})
          </div>
          <van-progress :show-pivot="false"
            :percentage="percentageis(shibum[item.id - 1] / tuanduilist[index + 1].team_line_pre * 100)" track-color="var( --text-color)"
            :color="vvlist[index]" />
        </div>
        <div class="text" :style="`color: ${vvlist[item.id]};`" v-else>
          <div style="font-size: 14px;">
            {{ $t('lang.d74') }}
          </div>
        </div>

      </div>

    </div> -->
    <van-swipe @change="changeswipe" class="my-swipe" :show-indicators="false"  indicator-color="white" :loop="false">
  <van-swipe-item v-for="item in testlist">
    <div class="my-swipe-item" :style="`background: url(${item.bgcard}) no-repeat;`">
       <div class="item-title">节点奖励</div>
       <div class="item-rate">20%</div>
       <div class="item-text">恭喜您晋升当前等级</div>
       <div class="item-level" :style="`color: ${item.color} ;`">{{item.level}}</div>
    </div>
  </van-swipe-item>
 
</van-swipe>
<div class="sipper">
  <div class="sipper-line">
    <span class="line-left">分享算力</span>
    <span class="line-right">521 / 100</span>
  </div>
  <van-slider :bar-height="3" active-color="#AE47FD" v-model="value" @change="onChange" style="margin-bottom: 27px;">
    <template #button>
    <div class="sipper-button"></div>
  </template>
    </van-slider>
  <div class="sipper-line">
    <span class="line-left">团队算力</span>
    <span class="line-right">521 / 100</span>
  </div>
  <van-slider :bar-height="3" active-color="#AE47FD" v-model="value" @change="onChange" style="margin-bottom: 27px;">
    <template #button>
    <div class="sipper-button"></div>
  </template>
    </van-slider>
    <div class="sipper-line">
    <span class="line-left">社区部门</span>
    <span class="line-right" style="color: #AFAFAF;">521 / 100</span>
  </div>
  <van-slider :bar-height="3" active-color="#F66931" v-model="value" @change="onChange" style="margin-bottom: 27px;">
    <template #button>
    <div ></div>
  </template>
    </van-slider>
</div>
    <p class="s_title"> {{ $t('lang.d62') }}</p>
    <div class="yaoq">
      <!-- <p class="y_title">邀请</p> -->
      <div class="y_title"> {{ $t('lang.d63') }}</div>
      <div class="y_line1" v-if="parent_id > 0"><span v-if="invite_code == ''">----</span><span v-else>{{ linkshow }}</span> </div>
      <div class="y_line1" v-else>{{ $t('lang.d96') }}</div>
      <div class="y_button copys" @click="copy"
        :data-clipboard-text="`${invite_linkleft}/#/?invite_code=${gerenmsg.invite_code}`" v-if="parent_id > 0">{{
          $t('lang.d64') }}</div>
      <div class="y_button " style="opacity: 0.4;" v-else>{{ $t('lang.d64') }}</div>
      <div class="y_button copys" @click="copy"
        :data-clipboard-text="gerenmsg.invite_code" v-if="parent_id > 0">复制邀请码</div>
      <div class="y_button " style="opacity: 0.4;" v-else>复制邀请码</div>
    </div>

    <div class="jilu">
      <p class="j_title"> {{ $t('lang.d65') }}</p>
      <div class="j_text" @click="popupfunopen" v-if="parent_id == 0">{{ $t('lang.d97') }}</div>
      <div class="j_text" v-else>{{ inviteinfo.recommender }}</div>
      <p class="j_title">我的市场</p>
      <div class="shichang">
        <div class="line">
          <div style="color: #999999; margin-bottom: 5px;">
            <span>{{ $t('lang.d103') }}</span>
            <span>{{ $t('lang.d104') }}</span>
          </div>
          <div style="color: var( --text-color);">
            <span>{{ marketinfo.team_num }}</span>
            <span>{{ marketinfo.valid_num }}</span>
          </div>
        </div>
        <div class="lin"></div>
        <div class="line">
          <div style="color: #999999;margin-bottom: 5px;">
            <span>{{ $t('lang.d105') }}</span>
            <span>{{ $t('lang.d106') }}</span>
          </div>
          <div style="color: var( --text-color);">
            <span>{{ marketinfo.recharge_num }}</span>
            <span>{{ marketinfo.withdraw_num }}</span>
          </div>
        </div>
      </div>
      <div class="j_sosuo">
        <span class="s_span"> {{ $t('lang.d66') }}</span>
        <div class="vant">
          <van-icon name="search" class="v_icon" />
          <!-- <input type="text" class="van_sosuo" placeholder="搜索"> -->
          <van-field type="text" v-model="souoInput" input-align="left" @input="sosuofun"
            :placeholder="`${$t('lang.swap362')}`" class="van-field2" />
        </div>

      </div>
      <div class="s_boxs">
        <div class="b_title">
          <span>{{ $t('lang.d67') }}</span>
          <span style="margin-left: 50px;">{{ $t('lang.d68') }}</span>
          <span>{{ $t('lang.d107') }}</span>
          <span>{{ $t('lang.d108') }}</span>
        </div>

        <div class="c_wlist" v-if="chelist.length == 0">
          <img src="@/assets/imgsnowball/wujilu.png" alt="">
          <p>{{ $t('lang.d57') }}</p>
        </div>
        <div v-for="item in chelist" v-else>
          <span>{{ item.address }}</span>
          <span style="color: var( --text-color);">{{ item.team_level_name }}</span>
          <span>{{ item.self_achievement }}</span>
          <span style="color: #39dba3ff;">{{ item.team_achievement }}</span>
        </div>
      </div>
    </div>
    <div class="popup" v-show="popupshow">
      <div class="p_main">
        <p class="m_title">{{ $t('lang.d76') }}</p>
        <span>{{ $t('lang.d77') }}</span>
        <van-field type="text" v-model="fromInput" input-align="left" placeholder="" class="van-field" />
        <div class="m_button" @click="postucbin">{{ $t('lang.d78') }}</div>
        <img src="@/assets/img/popupx.png" alt="" class="popupx" @click="popupfunclose">
      </div>

    </div>
  </div>
  </div>
 
</template>
  
<script>
import Clipboard from 'clipboard';
import { mapState } from 'vuex';
import { user, homedata, zhubi } from '@/api/index';
import topbutton from '@/components/topbutton.vue';
export default {
  data() {
    return {
      imgurl: "",
      popupshow: false,
      gerenmsg: {},
      chelist: [],
      inviteinfo: {},
      invite_linkleft: "",
      fromInput: "",
      souoInput: "",
      tuanduilist: [],
      nexttuandui: {},
      uptuandui: {},
      shibum: [],
      parent_id: 0,
      marketinfo: {},
      vvlist: [

        ' #D27650',
        ' #9BA0AC',
        ' #FD8905',
        ' #844CDF',
        ' #FE8A05',
        ' #3B5FD7',
        '#8A53DA'
      ],
      testlist:[
        {
          bgcard:require('@/assets/imgsnowball/card1.png'),
          color:'#477BFD',
          level:"一星节点"
        },
        {
          bgcard:require('@/assets/imgsnowball/card2.png'),
          color:'#8C5EFF',
          level:"两星节点"
        },
        {
          bgcard:require('@/assets/imgsnowball/card3.png'),
          color:'#FFB810',
          level:"三星节点"
        },
        {
          bgcard:require('@/assets/imgsnowball/card4.png'),
          color:'#7687AA',
          level:"四星节点"
        },
        {
          bgcard:require('@/assets/imgsnowball/card5.png'),
          color:'#FEB231',
          level:"五星节点"
        }
      ]
    };
  },

  created() {
    this.init()
    console.log(this.account);
  },
  mounted() {
    // this.vvspos(this.uptuandui.team_level)
    setTimeout(() => {
      this.vvspos(this.uptuandui.team_level)
    }, 1000)

  },
  watch: {
    account() {
      //获取账号
      this.init();
    },
    lang() {
      this.init();
    }
  },
  computed: {
    ...mapState(['account', 'lang']),
    linkshow() {
      var link = `${this.invite_linkleft}/#/?invite_code=${this.gerenmsg.invite_code}`
      const head = link.slice(0, 22);
      const tail = link.slice(link.length - 5);
      return head + "****" + tail;
    },

  },

  methods: {
    changeswipe(index){
         console.log(index);
    },
    percentageis(item) {
      if (item <= 100) {
        return item
      } else {
        return 100
      }

    },
    popupfunopen() {
      this.popupshow = true
    },
    popupfunclose() {
      this.popupshow = false
    },
    init() {
      this.getdata()
      this.getinfo()
      this.getinviteinfo()
      this.geturl()
      // this.getdengji()
      this.getimgurl()
      // this.getmarket()
    },
    sosuofun() {
      let data = {
        page_size: 10,
        address: this.souoInput
      }
      user.getucchildrenlist(data, this.account).then(res => {
        console.log(res);
        this.chelist = res.data.list
      })

    },
    vvspos(num) {
      console.log(num);
      var vvs = document.querySelector('.vvs')
      var vv = document.getElementById(`vv${num}`)

      var vvleft = this.integer(vv.offsetLeft - 10);
      //  var runleft =0
      //  console.log(vvleft);

      // var timer = setInterval(()=>{
      //           runleft+=10;
      //           console.log(runleft);
      //           vvs.scrollTo(runleft,0)
      //           if(runleft == vvleft){
      //              clearInterval(timer)
      //           }
      //  },20)



      vvs.scrollTo(vvleft, 0)
    },
    integer(num) {
      // 个位数
      let theUnit = parseInt(num % 10)
      // 十位数
      let tens = parseInt((num % 100) / 10)
      // 百位数
      let hundred = parseInt((num % 1000) / 100)
      // 千位数
      let thousand = parseInt((num % 10000) / 1000)
      // 以此类推
      let resNum = ''
      if (theUnit !== 5) {
        if (thousand === 0) {
          resNum = hundred.toString() + tens.toString() + '0'
        } else {
          resNum = thousand.toString() + hundred.toString() + tens.toString() + '0'
        }
      }
      return parseInt(resNum)
    },

    copy() {
      if(this.gerenmsg.invite_code == '') return this.$toast('您没有邀请码 !')
      this.invite_link = this.account;
      if (this.invite_link) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast(this.$t('lang.swap300'));
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast(this.$t('lang.swap301'));
          clipboard.destroy(); // 释放内存
        });
      }
    },
    getdata() {
      let data = {
        page_size: 10
      }
      user.getucchildrenlist(data, this.account).then(res => {
        console.log(res);

        var a = res.data.list
        this.chelist = a.map((item, index, arr) => {
          if (item.self_achievement > 0) {
            return item
          } else {
            return ''
          }
        })

        this.chelist = this.getarraytrim(this.chelist)
        console.log(this.chelist);
      })



    },
    getarraytrim(array) {
      var arr = []
      for (var i = 0; i < array.length; i++) {
        if (array[i] != "") {
          arr.push(array[i])

        }
      }
      console.log(arr);
      return arr;

    },
    async getdengji() {
      await zhubi.gettuandui(this.account).then(res => {
        console.log(res);
        this.nexttuandui = res.data.next_level
        this.shibum = []
        this.uptuandui = res.data.user_info
        this.shibum.push(this.uptuandui.dr_l1)
        this.shibum.push(this.uptuandui.dr_l2)
        this.shibum.push(this.uptuandui.dr_l3)
        this.shibum.push(this.uptuandui.dr_l4)
        this.shibum.push(this.uptuandui.dr_l5)
        console.log("www", this.shibum);
      })
      await zhubi.getproductteamlist(this.account).then(res => {
        console.log(res);
        var a = this.uptuandui.team_level;


        console.log("a", a);
        var b = res.data

        this.tuanduilist = b
        console.log(this.tuanduilist);
      })
    },
    getmarket() {
      zhubi.getproductmarketinfo(this.account).then(res => {
        this.marketinfo = res.data || {}
      })
    },
    getinfo() {
      user.getucinfo(this.account).then(res => {
        console.log("w", res);
        this.gerenmsg = res.data
        this.parent_id = this.gerenmsg.parent_id
      })
    },
    getimgurl() {
      homedata.gettokenimgurl().then(res => {
        this.imgurl = res.data.image_domain
      })
    },
    getinviteinfo() {
      user.getucinviteinfo(this.account).then(res => {
        console.log(res);
        this.inviteinfo = res.data
      })
    },
    geturl() {
      homedata.gettokenimgurl().then(res => {
        this.invite_linkleft = res.data.domain;
      })
    },
    postucbin() {
      this.popupshow = false
      let data = {
        parent_addr: this.fromInput,
        
      }
      user.postucbind(data, this.account).then(res => {
        console.log(res);
        this.init()
      })
    }
  },
  components: {
    topbutton
  },

};
</script>
  
<style lang="less" scoped>
.sipper{
  margin-top: 20px;
  width: 100%;
height: 201px;
border-radius: 16px;
opacity: 1;

box-sizing: border-box;
border: 1px solid #DEDEDE;
padding: 18px 20px;
.sipper-button{
   width: 8px;
   height: 8px;
   border-radius: 50%;
   border: 2px solid #AE47FD;
}
.sipper-line{
  margin-bottom: 13px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .line-left{
    font-size: 12px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0px;

font-feature-settings: "kern" on;
color: #3D3D3D;
  }
  .line-right{
    font-size: 12px;
font-weight: 500;
line-height: 18px;
text-align: right;
letter-spacing: 0px;

font-feature-settings: "kern" on;
color: #477BFD;
  }
}
}
.my-swipe{
  width: 100%;
  .my-swipe-item{
    position: relative;
    width:100%;
height: 162px;
     background: url(../../../assets/imgsnowball/card1.png) no-repeat;
     background-size: contain !important;
  }
  .item-title{
    position: absolute;
    left: 19px;
    top: 21px;
    font-size: 18px;
font-weight: 500;
  }
  .item-rate{
    position: absolute;
    left: 38px;
    top: 66px;
    font-size: 10px;
font-weight: 500;
line-height: 15px;
letter-spacing: 0px;
display: flex;
align-items: center;
justify-content: center;
    width: 33px;
height: 16px;
border-radius: 58px;
opacity: 1;

background: #D8D8D8;
  }
  .item-text{
    position: absolute;
    left: 19px;
    bottom: 20px;
    font-size: 12px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0px;

  }
  .item-level{
    position: absolute;
    right: 46px;
    bottom: 22px;
    font-family: YouSheBiaoTiHei;
font-size: 18px;
font-weight: normal;
line-height: 15px;
letter-spacing: 0px;

color: #477BFD;
  }
}
.shequ {
  width: 375px;
 border-radius: 20px 20px 0 0;
  padding: 20px;
  
  background: var( --text-color);

  .s_title {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    font-family: "Poppins";
  }

  .vvs {
    width: 100%;
    overflow-x: scroll;
    display: flex;

    .vv {
      flex-shrink: 0;
      width: 100%;
      height: 200px;

      border-radius: 14px;
      background-size: cover !important;
      margin-right: 30px;
      position: relative;

      .biao {
        width: 55px;
        height: 25px;
        border-radius: 46px 46px 56px 46px;
        color: #000;
        font-size: 10px;
        font-weight: 700;
        font-family: "PingFang";
        text-align: center;
        line-height: 25px;
        position: absolute;

        top: 25px;
        left: 30px;
      }

      .text {
        position: absolute;
        bottom: 20px;
        left: 30px;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        font-family: "PingFang";
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 70px;
      }
    }
  }

}

.yaoq {
  width: 100%;
  margin: 0 auto;

  padding: 20px;
  border-radius: 20px;
  opacity: 1;
  background: #F7F7F7;
  box-shadow: 0 0 12px 0 #F7F7F7;


  .y_line1 {
    width: 100%;
    height: 52px;
    border-radius: 10px;
    text-align: center;
    line-height: 52px;
    background: var( --text-color);
    font-size: 12px;
    color: #000;
  }

  .y_title {
    opacity: 1;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    font-family: "PingFang";
    margin-bottom: 20px;
  }

  .y_button {
    margin-top: 30px;
    width: 100%;
    height: 52px;
    border-radius: 10px;
    background: linear-gradient(-43.7deg, #1a70fdff 0%, #26c3ffff 100%);
    box-shadow: 0 0 12px 0 #080a1b1a;
    text-align: center;
    line-height: 52px;
    color: var( --text-color);
    font-size: 14px;
    font-weight: 700;
    font-family: "PingFang";
  }
}

.jilu {
  width: 100%;


  margin-top: 20px;


  .j_title {
    text-align: left;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    font-family: "PingFang";
  }

  .j_text {
    width: 100%;
    height: 59px;
    border-radius: 20px;
    background: #F7F7F7;
    box-shadow: 0 0 12px 0 #F7F7F7;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    font-family: "PingFang";
    text-align: center;
    line-height: 59px;
  }

  .j_sosuo {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .s_span {
      color: #000;
      font-size: 18px;
      font-weight: 700;
      font-family: "PingFang";
    }
  }
}

.van-field {
  width: 228px;
  margin: 0 auto;

  height: 48px;
  border-radius: 4px;
  font-weight: bold;
  background: #F7F7F7 !important;
  padding: 14px !important;

  /deep/ .van-field__control {
    font-size: 14px;
    color: #000;
    background: #F7F7F7 !important;
  }
}

.vant {
  width: 202px;
  height: 34px;
  position: relative;
  background: #F7F7F7;
  border-radius: 20px;

  .v_icon {
    margin-top: 12px;
    margin-left: 7px;
  }

  .van_sosuo {
    float: right;
    padding: 0;
    color: var( --text-color);
    width: 170px;
    height: 34px;

    border: none;
    background: none;
    box-shadow: 0 0 12px 0 #080a1b1a;
  }
}

.y_cont {
  width: 100%;
  margin-top: 30px;

  .c_li {
    color: #999999ff;
    font-size: 14px;
    font-weight: 700;
    font-family: "PingFang";
    padding: 10px;
  }
}

.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  transition: transform .5s ease;

  .p_main {
    width: 288px;
    height: 248px;
    border-radius: 10px;
    padding: 10px;
    background: var( --text-color);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -70%);

    .m_button {
      width: 228px;
      height: 48px;
      border-radius: 65px;
      margin: 0 auto;
      margin-top: 20px;
      color: var( --text-color);
      font-size: 14px;
      font-weight: 700;
      font-family: "PingFang";
      text-align: center;
      line-height: 48px;
      background: #5C90FE;
    }

    .m_title {
      color: #2f272aff;
      font-size: 20px;
      font-weight: 700;
      font-family: "PingFang";
      text-align: center;
    }

    span {
      color: #000000ff;
      font-size: 14px;
      font-weight: 700;
      font-family: "PingFang";
      float: left;
      margin-bottom: 20px;
    }
  }
}

.popupx {
  width: 36px;
  height: 36px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
}

.s_boxs {
  width: 100%;
  margin-top: 20px;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 16px;

  background: #F7F7F7;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .b_title {
    color: #999999ff;
    font-size: 14px;
    font-weight: 500;
    font-family: "PingFang";
  }

  .b_cont {
    color: #999999ff;
    font-size: 14px;
    font-weight: 700;
    font-family: "PingFang";
  }
}

.c_wlist {
  width: 100%;
  height: 219px;
  border-radius: 20px;
  opacity: 1;
  background: #F7F7F7;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 122px;
height: 92px;
  }

  p {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    font-family: "PingFang";
  }
}

.van-field2 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 !important;
  padding-top: 5px !important;
  color: var( --text-color);
  width: 170px;
  height: 34px;

  border: none;
  background: #f7f7f7 !important;
  box-shadow: 0 0 12px 0 #080a1b1a;

  /deep/ .van-field__control {
    font-size: 14px;
    color: var( --text-color);
  }
}

/deep/.van-progress {

  width: 278px;
  height: 6px;
}

.rate {
  width: 55px;
  height: 25px;
  border-radius: 46px 46px 56px 46px;
  color: var( --text-color);
  font-size: 10px;
  font-weight: 700;
  font-family: "PingFang";
  text-align: center;
  line-height: 25px;
  position: absolute;

  top: 65px;
  left: 110px;
}

.shichang {
  width: 100%;
  height: 155px;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 21px 16px;
  background: #F7F7F7;
  box-shadow: 0 0 12px 0 #F7F7F7;

  .line {
    height: 49%;
    width: 100%;

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  .lin {
    width: 100%;
    height: 1px;
    background: #3B3C40;
    margin-bottom: 10px;
  }
}</style>