// import addresses from '@/config/constants/contracts.js';
const addresses = {
  jackRouter: '0xA3eCBD7E1d95eB6662a64DFb96560AC909f58Bf4',
  jackFactory: '0xF31480d5edf8f49D6525223516EE05cCeB71B4A7',
  weth: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
};

export const getAddress = address => {
  return address;
};

export const getJackFactoryAddress = () => {
  return getAddress(addresses.jackFactory);
};

export const getJackRouterAddress = () => {
  return getAddress(addresses.jackRouter);
};

export const getWethAddress = () => {
  return getAddress(addresses.weth);
};
