<template>
  <div class="pendingSwap">
    <div class="van-dialog__header">
      <div class="text">{{ $t('lang.swap27') }}</div>
      <div class="cancel"
           @click="handleClose"></div>
    </div>
    <div class="btContent">
      <div v-if="pendingDetail.status == 0">
        <img class="awaits"
             src="@/assets/img/awaits.png"
             alt="" />
        <div class="word1">{{ $t('lang.swap19') }}</div>
        <div class="word2">{{ $t('lang.swap20') }} {{ pendingDetail.fromInput }} {{ fromCur.symbol }} {{ $t('lang.swap21') }} {{ pendingDetail.toInput }} {{ toCur.symbol }}</div>
        <div class="word3">{{ $t('lang.swap22') }}</div>
      </div>
      <div v-if="pendingDetail.status == 1">
        <img class="reslove"
             src="@/assets/img/reslove.png"
             alt="" />
        <div class="word1">{{ $t('lang.swap23') }}</div>
        <div class="word4"
             @click="viewHash(pendingDetail.hash)">{{ $t('lang.swap24') }}</div>
        <van-button type="primary"
                    class="closeBtn"
                    @click="handleClose">{{ $t('lang.swap25') }}</van-button>
      </div>
      <div v-if="pendingDetail.status == 2">
        <img class="wait"
             src="@/assets/img/reject.png"
             alt="" />
        <div>{{ $t('lang.swap26') }}</div>
        <van-button type="primary"
                    class="closeBtn"
                    @click="handleClose">{{ $t('lang.swap25') }}</van-button>
      </div>
      <div v-if="pendingDetail.status == 3">
        <img class="awaits"
             src="@/assets/img/awaits.png"
             alt />
        <div class="word1">{{ $t('lang.swap19') }}</div>
        <div class="word2">{{ $t('lang.swap123') }}{{removeDataCopy.fromRecent}} {{removeDataCopy.fromSymbol}}{{ $t('lang.swap112') }}{{removeDataCopy.toRecent}} {{removeDataCopy.toSymbol}}</div>
      </div>
      <div v-if="pendingDetail.status == 4">
        <img class="awaits"
             src="@/assets/img/awaits.png"
             alt />
        <div class="word1">{{ $t('lang.swap19') }}</div>
        <div class="word2">{{ $t('lang.swap124') }}{{ pendingDetail.fromInput }} {{ fromCur.symbol }} {{ $t('lang.swap112') }} {{ pendingDetail.toInput }} {{ toCur.symbol }}</div>
        <div class="word3">{{ $t('lang.swap22') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { viewHash } from '@/utils/common';

export default {
  name: 'pendingSwap',
  props: ['pendingDetail', 'fromCur', 'toCur', 'removeDataCopy'],
  methods: {
    viewHash,
    handleClose () {
      this.$emit('listenClose');
    },
  },
};
</script>

<style lang="less" scoped>
.pendingSwap {
  background: var( --box-bg-color);

  // width: 350px;
  .van-dialog__header {
    // width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    margin: 0 20px;
    font-weight: bold;
    font-size: 14px;
    color: var( --text-color);
    border-bottom: 1px solid #282f3a;
    .cancel {
      width: 16px;
      height: 16px;
      background-image: url('~@/assets/img/cancel.png');
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
  .btContent {
    width: 100%;
    padding: 0 16px 16px;
    text-align: center;
    color: var( --text-color);

    .wait {
      width: 250px;
    }
    .awaits {
      width: 110px;
      margin: 50px 0;
    }
    .reslove {
      width: 180px;
      margin: 20px 0;
    }
    .word1 {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: var( --text-color);
    }
    .word2 {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: var( --text-color);
      margin-top: 12px;
    }
    .word3 {
      font-size: 12px;
      line-height: 17px;
      color: var( --text-color);
      margin-top: 12px;
    }
    .word4 {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      margin-top: 12px;
      color: var( --text-color);
    }
    .closeBtn {
      height: 58px;
      line-height: 58px;
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      color: #000 !important;
      background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
      border-radius: 110px;
      margin-top: 18px;
    }
  }
  .tokenInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftItem {
      display: flex;
      align-items: center;
      .input {
        margin-left: 5px;
      }
      .tokenImg {
        height: 30px;
        width: 30px;
        border-radius: 5px;
      }
    }
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .confirmBtn {
    width: 100%;
    margin-top: 22px;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
